<template>
  <div>
    <v-layout class="conGrad" wrap justify-center pa-sm-16 v-if="!flag">
      <v-flex xs12 lg12 xl8 align-self-center py-4 px-sm-16 px-md-6 px-lg-16>
        <v-card
          :min-height="$vuetify.breakpoint.name == 'xl' ? '80vh' : '50vh'"
          flat
          color="white"
        >
          <v-layout wrap justify-center py-md-12 pt-xl-16>
            <v-flex xs12 md6 lg6 align-self-center>
              <v-layout wrap justify-center px-3 px-sm-12>
                <v-flex xs12 align-self-center>
                  <v-card :rounded="true" flat light>
                    <v-form ref="form" v-model="valid" lazy-validation>
                      <v-layout wrap>
                        <v-flex xs12 px-4 px-sm-8 align-self-center text-left>
                          <span class="con3">Get A Quote</span>
                        </v-flex>
                        <v-flex xs12 px-4 px-sm-8>
                          <v-layout wrap>
                            <v-flex xs12 text-left>
                              <v-text-field
                                class="tes"
                                label="Name"
                                v-model="quoteRequest.name"
                                :rules="nameRules"
                                required
                                flat
                                solo
                              ></v-text-field>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 pb-4 align-self-center>
                          <v-divider></v-divider>
                        </v-flex>

                        <v-flex xs12 px-4 px-sm-8>
                          <v-layout wrap>
                            <!-- <v-flex xs12 text-left>
                  <span class="con4">Email</span>
                </v-flex> -->
                            <v-flex xs12 text-left>
                              <v-text-field
                                required
                                :rules="emailRules"
                                class="tes"
                                label="Email"
                                v-model="quoteRequest.email"
                                flat
                                solo
                              ></v-text-field>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 pb-4 align-self-center>
                          <v-divider></v-divider>
                        </v-flex>

                        <v-flex xs12 px-4 px-sm-8>
                          <v-layout wrap>
                            <!-- <v-flex xs12 text-left>
                  <span class="con4">Phone Number</span>
                </v-flex> -->
                            <v-flex xs12 text-left>
                              <v-text-field
                                class="tes"
                                :rules="phoneRules"
                                label="Phone Number"
                                v-model="quoteRequest.phone"
                                flat
                                solo
                              ></v-text-field>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 pb-4 align-self-center>
                          <v-divider></v-divider>
                        </v-flex>
                        <v-flex xs12 px-4 px-sm-8>
                          <v-layout wrap>
                            <!-- <v-flex xs12 text-left>
                  <span class="con4">about</span>
                </v-flex> -->
                            <v-flex xs12 text-left>
                              <v-textarea
                                class="tes"
                                v-model="quoteRequest.about"
                                label="About Project"
                                flat
                                :rows="5"
                                solo
                              ></v-textarea>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 align-self-center>
                          <v-spacer></v-spacer>
                        </v-flex>
                      </v-layout>
                    </v-form>
                  </v-card>

                  <!-- ELSE  -->
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex
              xs12
              md6
              lg6
              pl-4
              pl-sm-16
              pt-12
              pt-sm-10
              pt-md-8
              pt-xl-16
              align-self-center
            >
              <v-layout wrap>
                <v-flex pt-10 xs12 align-self-center text-left>
                  <span>Services</span>
                </v-flex>
                <v-flex xs12 align-self-center text-left>
                  <v-layout wrap>
                    <v-flex xs12 sm12 md12 lg6>
                      <v-checkbox-group>
                        <v-checkbox
                          multiple
                          v-model="quoteRequest.categories"
                          v-for="item in categories"
                          :key="item._id"
                          :value="item._id"
                          :ripple="false"
                        >
                          <template v-slot:label>
                            <span class="input__label">{{ item.name }}</span>
                          </template>
                        </v-checkbox>
                      </v-checkbox-group>
                    </v-flex>
                  </v-layout>
                </v-flex>

                <v-flex pb-4  align-self-center>
                  <v-btn
                    @click="validate"
                    :disabled="!valid"
                    class="graddy"
                    rounded 
                    :ripple="false"
                  >
                    <span
                      >Submit
                      <v-icon>mdi-arrow-right</v-icon>
                    </span>
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-flex>
            <!-- <v-flex xs12 pb-4 align-self-center>
                <v-divider></v-divider>
              </v-flex> -->
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
    <v-app v-if="flag" class="conGrad">
      <v-layout justify-center pt-0 pt-md-8>
        <v-flex xs12 sm12 md6 lg3 align-self-center>
          <v-card class="tanGrad rot" :rounded="true" dark>
            <v-layout wrap pa-8>
              <v-flex xs12 pb-1 align-self-end text-right>
                <v-btn plain @click="flag = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-flex>
              <v-flex
                xs12
                xl6
                pt-16
                pb-0
                :style="'padding-top: 180px !important'"
                align-self-center
                text-left
              >
                <span class="con5">Thank You.</span>
              </v-flex>
            </v-layout>

            <v-layout wrap py-2>
              <v-flex xs12 align-self-center>
                <v-divider></v-divider>
              </v-flex>
            </v-layout>
            <v-layout wrap pa-8>
              <v-flex xs12 lg8 xl6 py-0 align-self-center text-left>
                <span class="con6"> We’ll be in touch. Shortly! </span>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>
    </v-app>
  </div>
</template>

<script>
// import Cards from "./card";
import axios from "axios";
export default {
  components: {
    // Cards,
  },
  data() {
    return {
      ServerError: false,
      categories: [],
      show: false,
      valid: true,
      flag: false,
      quoteRequest: {
        name: "",
        about: "",
        phone: "",
        email: "",
        categories: [],
      },
      showSnackBar: false,
      timeOut: 5000,
      msg: null,
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => v.length <= 30 || "Name must be less than 30 characters",
      ],

      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
      phoneRules: [
        (v) => !!v || "Phone is required",
        (v) => v.length == 10 || "Invalid Phone Number",
      ],
    };
  },
  mounted() {
    this.getCategoryData();
  },
  methods: {
    getCategoryData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "product/category/getlist",
      })
        .then((response) => {
          if (response.data.status) {
            this.categories = response.data.data;
            this.appLoading = false;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    validate() {
      if (this.$refs.form.validate()) this.addQuote();
    },
    addQuote() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/quote/add",
        data: this.quoteRequest,
      })
        .then((response) => {
          if (response.data.status) {
            this.appLoading = false;
            Object.keys(this.quoteRequest).forEach(
              (key) => (this.quoteRequest[key] = null)
            );
            this.flag = true;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>

<style scoped >
.graddy {
  background: transparent
    linear-gradient(
      100deg,
      #f5a624 0%,
      #f9b52c 44%,
      #fbba2f 59%,
      #fdc033 78%,
      #fdc033 78%,
      #ffc837 100%
    );
}

.conGrad {
  /* background-image: transparent */

  /* background-image: linear-gradient(#0d0d0d, #1e1e1e); */
  background: transparent
    linear-gradient(104deg, #0d0d0d 0%, #1e1e1e 100%, transparent 100%);

  /* linear-gradient(180deg, #0d0d0d 0%, #1e1e1e 100%) 0% 0% no-repeat
    padding-box;
  opacity: 1; */
  /* background-color: orange; */
}
</style>