<template>
  <div style="padding-top: 0px">
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#FF6907"
      spinner="bar-fade-scale"
    />
    <!-- <ServerError v-if="ServerError" /> -->
    <v-snackbar v-model="showSnackBar" color="#FF6907" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #ffffff">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #ffffff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs12>
        <Contact />
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import Contact from "@/components/QuoteRequest/quoteView";
export default {
  data() {
    return {
      
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
    }
  },
  components: {
    Contact,
  },
};
</script>